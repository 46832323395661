<template>
	<el-main class="bg">
		<el-form ref="form" :model="form" status-icon label-width="120px" v-if="is_view()">
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="房号" prop="room_number">
					<el-input id="room_number" v-model="form['room_number']" placeholder="请输入房号"
							  v-if="user_group === '管理员' || (form['room_management_id'] && $check_field('set','room_number')) || (!form['room_management_id'] && $check_field('add','room_number'))" :disabled="disabledObj['room_number_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','room_number')">{{form['room_number']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="类型" prop="type">
					<el-select id="type" v-model="form['type']"
						v-if="user_group === '管理员' || (form['room_management_id'] && $check_field('set','type')) || (!form['room_management_id'] && $check_field('add','type'))">
						<el-option v-for="o in list_type" :key="o" :label="o" :value="o">
						</el-option>
					</el-select>
					<div v-else-if="$check_field('get','type')">{{form['type']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="图片" prop="picture">
					<el-upload id="picture" class="avatar-uploader" drag
						accept="image/gif, image/jpeg, image/png, image/jpg" action="" :http-request="upload_picture"
						:show-file-list="false" v-if="user_group === '管理员' || (form['room_management_id'] && $check_field('set','picture')) || (!form['room_management_id'] && $check_field('add','picture'))">
						<img v-if="form['picture']" :src="$fullUrl(form['picture'])" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-image v-else-if="$check_field('get','picture')" style="width: 100px; height: 100px"
						:src="$fullUrl(form['picture'])" :preview-src-list="[$fullUrl(form['picture'])]">
						<div slot="error" class="image-slot">
							<img src="../../../public/img/error.png" style="width: 90px; height: 90px" />
						</div>
					</el-image>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="使用状态" prop="use_status">
					<el-select id="use_status" v-model="form['use_status']"
						v-if="user_group === '管理员' || (form['room_management_id'] && $check_field('set','use_status')) || (!form['room_management_id'] && $check_field('add','use_status'))">
						<el-option v-for="o in list_use_status" :key="o" :label="o" :value="o">
						</el-option>
					</el-select>
					<div v-else-if="$check_field('get','use_status')">{{form['use_status']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="备注" prop="remarks">
					<el-input type="textarea" id="remarks" v-model="form['remarks']" placeholder="请输入备注"
						v-if="user_group === '管理员' || (form['room_management_id'] && $check_field('set','remarks')) || (!form['room_management_id'] && $check_field('add','remarks'))" :disabled="disabledObj['remarks_isDisabled']"></el-input>
					<div v-else-if="$check_field('get','remarks')">{{form['remarks']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item label="价格" prop="price">
					<el-input-number id="price" v-model.number="form['price']"
						v-if="user_group === '管理员' || (form['room_management_id'] && $check_field('set','price')) || (!form['room_management_id'] && $check_field('add','price'))"></el-input-number>
					<div v-else-if="$check_field('get','price')">{{form['price']}}</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :lg="8">
				<el-form-item>
					<el-button type="primary" @click="submit()">提交</el-button>
					<el-button @click="cancel()">取消</el-button>
				</el-form-item>
			</el-col>

		</el-form>
	</el-main>
</template>

<script>
	import mixin from "@/mixins/page.js";

	export default {
		mixins: [mixin],
		data() {
			return {
				field: "room_management_id",
				url_add: "~/api/room_management/add?",
				url_set: "~/api/room_management/set?",
				url_get_obj: "~/api/room_management/get_obj?",
				url_upload: "~/api/room_management/upload?",

				query: {
					"room_management_id": 0,
				},

				form: {
					"room_number":'', // 房号
					"type":'', // 类型
					"picture":'', // 图片
					"use_status":'', // 使用状态
					"remarks":'', // 备注
					"price":0, // 价格
					"room_management_id": 0 // ID

				},
				disabledObj:{
					"room_number_isDisabled": false,
					"type_isDisabled": false,
					"picture_isDisabled": false,
					"use_status_isDisabled": false,
					"remarks_isDisabled": false,
				},
				//类型选项列表
				list_type: ['单人','双人'],
				//使用状态选项列表
				list_use_status: ['空闲','使用中','维修'],

			}
		},
		methods: {
			/**
			 * 上传图片
			 * @param {Object} param图片参数
			 */
			upload_picture(param) {
				this.uploadFile(param.file, "picture");
			},

			/**
			 * 获取对象之前
			 * @param {Object} param
			 */
			get_obj_before(param) {
				var form = "";
				if(this.form && form){
					Object.keys(this.form).forEach(key => {
					Object.keys(form).forEach(dbKey => {
						if(dbKey === "charging_standard"){
							this.form['charging_rules'] = form[dbKey];
							this.disabledObj['charging_rules_isDisabled'] = true;
						};
						if(key === dbKey){
							this.disabledObj[key+'_isDisabled'] = true;
						}
					})
				})
				}
				return param;
			},

			/**
			 * 获取对象之后
			 * @param {Object} json
			 * @param {Object} func
			 */
			get_obj_after(json, func){

			},

			is_view(){
				var bl = this.user_group == "管理员";

				if(!bl){
					bl = this.$check_action('/room_management/table','add');
					console.log(bl ? "你有表格添加权限视作有添加权限" : "你没有表格添加权限");
				}
				if(!bl){
					bl = this.$check_action('/room_management/table','set');
					console.log(bl ? "你有表格添加权限视作有修改权限" : "你没有表格修改权限");
				}
				if(!bl){
					bl = this.$check_action('/room_management/view','add');
					console.log(bl ? "你有视图添加权限视作有添加权限" : "你没有视图添加权限");
				}
				if(!bl){
					bl = this.$check_action('/room_management/view','set');
					console.log(bl ? "你有视图修改权限视作有修改权限" : "你没有视图修改权限");
				}
				if(!bl){
					bl = this.$check_action('/room_management/view','get');
					console.log(bl ? "你有视图查询权限视作有查询权限" : "你没有视图查询权限");
				}

				console.log(bl ? "具有当前页面的查看权，请注意这不代表你有字段的查看权" : "无权查看当前页，请注意即便有字段查询权限没有页面查询权限也不行");

				return bl;
			},

			/**
			 * 上传文件
			 * @param {Object} param
			 */
			uploadimg(param) {
				this.uploadFile(param.file, "avatar");
			}

		},
		created() {
		}
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
